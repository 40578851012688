import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CREATE_PROMOTION_SUCCESS, createPromotion } from '../promotions/promotions.actions';
import { PromotionForm } from './form.component';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class PromotionCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.props.setPageTitle('New Promotion');
    this.handleCreate = this.handleCreate.bind(this);
  }

  async handleCreate(promotionData, promotionImage) {
    const response = await this.props.createPromotion(promotionData, promotionImage);

    if (response.type === CREATE_PROMOTION_SUCCESS) {
      this.props.handleToastMessage('Promotion Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create promotion.', true);
    }
  }

  render() {
    return (
      <div className="row page-padding-bottom">
        <PromotionForm history={this.props.history} submitAction={this.handleCreate} handleToastMessage={this.props.handleToastMessage} />
      </div>
    );
  }
}

PromotionCreateContainer.propTypes = {
  createPromotion: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};


const prepareForExport = compose(
  withRouter,
  connect(
    () => {},
    {
      createPromotion,
      handleToastMessage,
      setPageTitle,
    }
  ),
);

export default prepareForExport(PromotionCreateContainer);
