import { Fab, Input, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, slice } from 'lodash';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getPromotions } from './promotions.actions';
import { setPageTitle } from '../layout/layout.actions';
import Colors from '../../styles/colors';

class PromotionsContainer extends Component {
  constructor(props) {
    super(props);

    [].map((key) => (this[key] = this[key].bind(this)));
  }

  state = {
    order: 'asc',
    orderBy: 'calories',
    currentPromotions: [],
    filteredPromotions: [],
    page: 0,
    rowsPerPage: 10,
    filters: {},
  };

  async UNSAFE_componentWillMount() {
    this.props.setPageTitle('Promotions');
    await this.props.getPromotions();

    let rowsPerPage = localStorage.getItem('rowsPerPage');
    if (parseInt(rowsPerPage, 10) >= 0) {
      this.setState({ rowsPerPage: parseInt(rowsPerPage, 10) });
    }
    this.buildCurrentPromotions();
  }

  buildCurrentPromotions() {
    let { page, rowsPerPage } = this.state;
    let promotions = this.filterArray(this.props.promotions, this.state.filters);
    this.setState({
      currentPromotions: slice(promotions, page * rowsPerPage, (page + 1) * rowsPerPage),
      filteredPromotions: promotions,
    });
  }

  initializeList(locations) {
    return locations.map((location) => {
      location.typeDisplayable = this.convertType(location.type);
      location.promotionName = location.promotion ? location.promotion.name : '';
      return location;
    });
  }

  filterArray(array, filters) {
    let currentArray = array;
    if (array) {
      currentArray = this.initializeList(currentArray);
      currentArray = array
        .map((record) => {
          let returnRecord = record;
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).map((filter) => {
              if (returnRecord) {
                if (
                  !isEmpty(filters[filter]) &&
                  (!record || !record[filter] || !record[filter].toLowerCase().includes(filters[filter].toLowerCase()))
                ) {
                  returnRecord = undefined;
                }
              }
              return returnRecord;
            });
          }
          return returnRecord;
        })
        .filter((record) => (record ? true : false));
    }
    return currentArray;
  }

  convertType(type) {
    if (type === 'MAMAVA') {
      return 'Lactation Suite';
    } else if (type === 'LOCATION') {
      return 'Designated Location';
    } else {
      return 'Unknown';
    }
  }

  handleUpdateFilters(filter, value) {
    let filters = this.state.filters;
    filters[filter] = value;
    this.setState({ state: { filters } }, this.buildCurrentPromotions);
  }

  handleLocationFilterChange = (event) => {
    this.handleUpdateFilters('name', event.target.value);
  };

  handlePromotionFilterChange = (event) => {
    this.handleUpdateFilters('promotionName', event.target.value);
  };

  handleStatusFilterChange = (event) => {
    this.handleUpdateFilters('status', event.target.value);
  };

  handleStateFilterChange = (event) => {
    this.handleUpdateFilters('state', event.target.value);
  };

  handleCityFilterChange = (event) => {
    this.handleUpdateFilters('city', event.target.value);
  };

  handleTypeFilterChange = (event) => {
    this.handleUpdateFilters('typeDisplayable', event.target.value);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, this.buildCurrentPromotions);
  };

  handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value }, this.buildCurrentPromotions);
  };

  render() {
    let { classes } = this.props;
    console.log('here are the current promotions', this.state.currentPromotions);

    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '75vh', paddingTop: 10, overflowY: 'auto' }}>
        {this.props.isLoading && <div className="loader" />}
        {!this.props.isLoading && (
          <div className={classes.tableWrapper} id="tableWrapper">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>
                    <div>Promotion</div>
                    <div>
                      <Input
                        margin="none"
                        id="filterPromotionName"
                        label="Message"
                        // color="secondary"
                        variant="caption"
                        placeholder="filter"
                        inputProps={{ maxLength: 120 }}
                        className={classes.textField}
                        style={{ marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, fontSize: 12 }}
                        classes={{
                          // textFieldInput: classes.textField,
                          underline: classes.underline,
                          root: classes.root,
                        }}
                        fullWidth
                        value={this.state.filters.name}
                        onChange={this.handleLocationFilterChange}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div>Image URL</div>
                    <div>
                      <Input
                        margin="none"
                        id="filterPromotionName"
                        label="Message"
                        // color="secondary"
                        variant="caption"
                        placeholder="filter"
                        inputProps={{ maxLength: 120 }}
                        className={classes.textField}
                        style={{ marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, fontSize: 12 }}
                        classes={{
                          // textFieldInput: classes.textField,
                          underline: classes.underline,
                          root: classes.root,
                        }}
                        // classes={{ focus: classes.textFieldFocus}}
                        fullWidth
                        value={this.state.filters.imageRef}
                        onChange={this.handlePromotionFilterChange}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div>Promotion URL</div>
                    <div>
                      <Input
                        margin="none"
                        id="filterCity"
                        label="Message"
                        // color="secondary"
                        variant="caption"
                        placeholder="filter"
                        inputProps={{ maxLength: 120 }}
                        className={classes.textField}
                        style={{ marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, fontSize: 12 }}
                        classes={{
                          // textFieldInput: classes.textField,
                          underline: classes.underline,
                          root: classes.root,
                        }}
                        // classes={{ focus: classes.textFieldFocus}}
                        fullWidth
                        value={this.state.filters.promotionURL}
                        onChange={this.handleCityFilterChange}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {this.state.currentPromotions.map((promotion, i) => {
                  return (
                    <TableRow
                      key={promotion.id}
                      className="clickable"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `promotions/${promotion.id}/edit`,
                        })
                      }
                    >
                      <TableCell className={classes.cell} component="td" scope="row">
                        {promotion.name}
                      </TableCell>
                      <TableCell className={classes.cell}>{promotion.imageRef}</TableCell>
                      <TableCell className={classes.cell}>{promotion.promotionURL}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {!this.props.isLoading && (
          <div style={{ flex: 0, paddingRight: 100 }}>
            <TablePagination
              component="div"
              count={this.state.filteredPromotions.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              style={{ flex: 0 }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
        )}
        {!this.props.isLoading && (
          <div style={{ flex: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.button}
              onClick={() => this.props.history.push('/promotions/create')}
            >
              <AddIcon />
            </Fab>
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(3),
    margin: 20,
  },
  table: {
    minWidth: 1024,
    padding: 20,
  },
  tableWrapper: {
    padding: '1rem',
    overflowX: 'auto',
  },
  tableBody: {
    overflowY: 'auto',
    height: '75vh',
  },
  button: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  head: {
    position: 'sticky',
    backgroundColor: Colors.white,
    top: 0,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
    fontSize: 16,
  },
  cell: {
    color: Colors.primary.main,
    // backgroundColor: Colors.primary.background,
  },
  input: {
    height: 20,
  },
  link: {
    color: Colors.primary.main,
    textDecoration: 'none',
    // fontWeight: '800',
  },
  underline: {
    // color: Colors.secondary.main,
    // borderBottomColor: Colors.secondary.main,
    '&:after': {
      // The source seems to use this but it doesn't work
      // borderBottomColor: Colors.secondary.main,
    },
  },
  textFieldFocus: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
  },
});

PromotionsContainer.propTypes = {
  promotions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getPromotions: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions.get('promotions'),
    isLoading: state.promotions.get('isLoading'),
    errors: state.promotions.get('errors'),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getPromotions,
    }
  ),
);

export default prepareForExport(PromotionsContainer);