import { Button, Card, CardContent, Fade, FormControl, InputLabel, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

export const PromotionForm = ({ promotion = ({ name: '', promotionURL: '', image: undefined }), submitAction, handleToastMessage, history }) => {

  const [promotionState, setPromotionState] = useState(promotion);
  const [imageToUpload, setImageToUpload] = useState(null);
  const uploadRef = useRef(null);

  const isFormValid = () => {
    return !isEmpty(promotionState.name) && !isEmpty(promotionState.promotionURL) && promotionState.image;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitAction(({ name: promotionState.name, promotionURL: promotionState.promotionURL }), imageToUpload);
  };

  const addImage = (event) => {
    const file = event.target.files[0];
    try {
      setImageToUpload(file);
      setPromotionState((prev) => ({ ...prev, image: URL.createObjectURL(file) }));
    } catch (e) {
      handleToastMessage('An error occurred while uploading the file; please try again.', true);
    }
  };
  return (
    <Fade id="formFade" in={true}>
      <div id="formRowDiv" style={styles.container}>
        {promotionState &&
          <Card style={{ flex: 1 }}>
            <CardContent>
              <div style={styles.cardContent}>
                <div key={'name'}>
                  <TextField
                    id={'name'}
                    label={'Name'}
                    fullWidth
                    value={promotionState?.name}
                    onChange={(event) => {
                      return setPromotionState((prev) => ({ ...prev, name: event.target.value }));
                    }}
                  />
                </div>
                <div key={'promotionURL'}>
                  <TextField
                    id={'promotionURL'}
                    label={'Promotion Url'}
                    fullWidth
                    value={promotionState?.promotionURL}
                    onChange={(event) => {
                      return setPromotionState((prev) => ({ ...prev, promotionURL: event.target.value }));
                    }}
                  />
                </div>
                <div>
                  <FormControl style={styles.formControl} >
                    <InputLabel shrink htmlFor="imageDiv">Image</InputLabel>
                  </FormControl>

                  <div id="imageDiv">
                    <div>
                      {(promotionState?.image) && (
                        <div style={{ marginBottom: 10 }}>
                          <img src={promotionState.image} style={{ height: 100 }} alt="promotion" />
                        </div>
                      )}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ marginRight: '1rem' }}
                          onClick={() => { uploadRef.current.click(); }}
                        >
                          {promotionState?.image ? 'Replace' : 'Set'} Image
                        </Button>

                        <input
                          type="file"
                          id="addImage"
                          style={{ display: 'none' }}
                          className="show-for-sr"
                          ref={uploadRef}
                          onChange={addImage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '4rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '1rem' }}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </CardContent>
          </Card>}
      </div>
    </Fade>
  );
};

const styles = ({
  cardContent: {
    gap: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    margin: 10,
  },
  formControl: {
    minWidth: 75,
  },
});

PromotionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  promotion: PropTypes.object,
  submitAction: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};
